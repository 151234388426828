import React from 'react';
import Image from '../image'

import ArrowRight from '@heroicons/react/solid/ArrowRightIcon';
import FireIcon from '@heroicons/react/solid/FireIcon';
import EmojiSadIcon from '@heroicons/react/solid/EmojiSadIcon';
import QuestionMarkCircleIcon from '@heroicons/react/solid/QuestionMarkCircleIcon';
import CurrencyDollarIcon from '@heroicons/react/solid/CurrencyDollarIcon';
import SwitchHorizontalIcon from '@heroicons/react/solid/SwitchHorizontalIcon';



const SuggestionItem = ({ title, className, bgClassName, icon }) => {
  const SuggestionIcon = icon;
  return (
    <div className="relative flex items-center px-2 py-2 bg-gray-800 rounded-2xl">
      <div className={`${bgClassName || 'bg-[#F4E3B8]'} rounded-xl p-[8px] sm:p-[14px] flex items-center justify-center`}>
        <SuggestionIcon className="h-6 w-6 text-gray-100" />
      </div>
      <p className="pl-4">
        {title}
      </p>
    </div>
  );
}


const HighlightText = () => (
  <div className="mt-8 md:mt-0 md:pl-20 h-full">
    <div className="flex items-center h-full">
      <div>
        <h2 className="font-extrabold text-4xl sm:text-5xl mb-2">
          Peek into conversations
        </h2>
        <p className="text-desktop-subheading text-slate-body">
          No need to read the full subreddit history, GummySearch surfaces the most important threads
        </p>
        <div className="mt-8 space-y-2 sm:space-y-4">
          <SuggestionItem title="Pain Points" icon={EmojiSadIcon} bgClassName={'bg-red-500'} />
          <SuggestionItem title="Solution Requests" icon={QuestionMarkCircleIcon}  bgClassName={'bg-cyan-500'} />
          <SuggestionItem title="Money Talk" icon={CurrencyDollarIcon}  bgClassName={'bg-green-500'} />
          <SuggestionItem title="Hot Discussions" icon={FireIcon}  bgClassName={'bg-yellow-500'} />
          <SuggestionItem title="Seeking Alternatives" icon={SwitchHorizontalIcon}  bgClassName={'bg-purple-500'} />
        </div>
        
      </div>
    </div>
  </div>
);

const Feature10 = () => {
  return (
    <section className="bg-slate-light font-dm-sans py-12 md:py-[90px]">
      <div className="mx-6 md:m-auto max-w-default rounded-3xl">
        <div className="items-center justify-center h-full sm:flex sm:flex-row-reverse py-1">
          {/*<div className="relative md:w-1/2 md:mt-0">
            <Image fileName="feature-audience-suggestions-md.png" alt="Solution Requests in Audience" />
          </div>*/}
          
          <div className="sm:w-1/2">
            <HighlightText />
          </div>

          <div className="bg-gradient-to-r from-feeling-moody-start mt-20 sm:mt-0 flex justify-center items-center to-feeling-moody-end m-auto h-[326px] w-[326px] md:h-[516px] md:w-[516px] rounded-full p-[42px]">
            <Image fileName="feature-audience-suggestions-sm.png"
              className="shadow-xl border-2 border-gray-800 rounded-xl"
              alt="Solution Requests in Audience"
            />
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Feature10;
