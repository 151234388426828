import React from 'react';
import { Link } from 'gatsby';

import Image from '../image'

import background from "../../images/feature-audiences.png"
import backgroundSmall from "../../images/feature-audiences-sm.png"

import SignupModalTrigger from './signupModal'

const Feature28 = ({hideFooter, setHideFooter}) => {
  return (
    <section className="min-h-screen font-dm-sans relative">
      <div className="absolute w-full h-full z-10 opacity-0 sm:opacity-20 bg-cover bg-no-repeat lg:bg-cover bg-center"
        style={{ backgroundImage: `url(${background})` }}></div>
      <div className="absolute w-full h-full z-10 opacity-20 sm:opacity-0 bg-contain bg-no-repeat lg:bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundSmall})` }}></div>
      {/*<div className="absolute h-full w-full bg-cyan-100 z-0 opacity-0"></div>*/}
      
      <div className="absolute h-full w-full z-10 p-4">
        <div className="px-2 sm:px-6 bg-cover min-h-screen bg-trans-grad md:px-0 py-0">
          <div className="flex items-center justify-center min-h-screen w-full">
            <div className="md:min-w-[558px] max-w-[558px] px-6 md:px-0 bg-gray-800 rounded-2xl shadow-2xl">
              <div className="my-[61px] text-center max-w-[362px] m-auto">
                <h3 className="mt-4 font-extrabold text-3xl sm:text-4xl">
                  Unlock Reddit Today
                </h3>
                <p className="text-lg sm:text-xl mt-4 text-slate-body">
                  Get your customer’s pains, desires, questions, and opinions at your fingertips
                </p>
                <div className="flex justify-center mt-8">
                  <SignupModalTrigger initiator="cta-end-button">
                    <div className="flex items-center font-bold inline-block cursor-pointer justify-center w-full py-4 px-8 text-white rounded-lg bg-green-500 hover:opacity-90">
                      <span>Discover your audience</span>
                    </div>
                  </SignupModalTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {false && hideFooter ? (
        <div className="absolute bottom-4 right-4 text-white flex space-x-2">
          <div className="text-sm opacity-75 hover:opacity-100 cursor-pointer" onClick={() => {
            setHideFooter(false);
            // todo: scroll to footer
          }}>Show more</div>
        </div>
      ) : ''}

    </section>
  );
};

export default Feature28;
