import React from 'react';
import { Link } from 'gatsby';
import Image from '../image'


import ArrowRight from '@heroicons/react/solid/ArrowRightIcon';
import SpeakerphoneIcon from '@heroicons/react/outline/SpeakerphoneIcon';
import EmojiSadIcon from '@heroicons/react/solid/EmojiSadIcon';
import QuestionMarkCircleIcon from '@heroicons/react/solid/QuestionMarkCircleIcon';
import CurrencyDollarIcon from '@heroicons/react/solid/CurrencyDollarIcon';
import SwitchHorizontalIcon from '@heroicons/react/solid/SwitchHorizontalIcon';

/**
 *
 * @returns Left Side Section With Text
 */


const SuggestionItem = ({ title, className, bgClassName, icon }) => {
  const SuggestionIcon = icon;
  return (
    <div className="relative flex items-center px-2 py-2 bg-gray-800 rounded-2xl">
      <div className={`bg-gradient-to-l from-green-600 to-feeling-moody-end rounded-xl p-[8px] sm:p-[10px] flex items-center justify-center`}>
        <SuggestionIcon className="h-6 w-6 text-gray-100" />
      </div>
      <p className="pl-4">
        {title}
      </p>
    </div>
  );
}

const HighlightText = () => (
  <div className="md:w-1/2 sm:pr-32 h-full">
    <div className="flex items-center h-full">
      <div>
        <h2 className="font-extrabold text-4xl sm:text-5xl mb-2">
          Track what matters
        </h2>
        <p className="text-desktop-subheading text-slate-body">
          Get notified of conversations related to your business, and be the first to contribute to them
        </p>

        <div className="mt-8 space-y-2 sm:space-y-4">
          <SuggestionItem title="Requests for your product category" icon={CurrencyDollarIcon} bgClassName={'bg-green-500'} />
          <SuggestionItem title="Pain points you can help with" icon={EmojiSadIcon}  bgClassName={'bg-red-500'} />
          <SuggestionItem title="Complaints about your competitors" icon={SwitchHorizontalIcon}  bgClassName={'bg-yellow-500'} />
          <SuggestionItem title="People discussing your brand" icon={SpeakerphoneIcon}  bgClassName={'bg-cyan-500'} />
        </div>

        {/*<div className="hidden lg:block mt-8">
          <Link to="/insights/keywords-to-track-on-reddit/">
            <div className="flex">
              <p className="text-base text-slate-body hover:underline">
                Full list of recommended keywords to track
              </p>
              <ArrowRight className="w-4 ml-2" />
            </div>
          </Link>
        </div>*/}
      </div>
    </div>
  </div>
);

const Feature21 = () => {
  return (
    <section className="bg-slate-light font-dm-sans px-4 lg:px-0 py-12 md:py-[90px]">
      <div className="m-auto mx-6 max-w-default rounded-3xl md:mx-auto">
        <div className="items-center justify-center h-full md:flex">
          <HighlightText />
          
          <div className="md:w-1/2 mt-9 md:mt-0">
            <div className="md:my-[32px]">
              <div className="bg-gradient-to-r from-feeling-moody-start flex justify-center items-center to-feeling-moody-end m-auto h-[326px] w-full md:h-[516px] md:w-[516px] rounded-full py-[10px]">
                <Image fileName="feature-track-sm.png"
                  className="shadow-xl border-2 border-gray-800 rounded-xl"
                  alt="Social Listening Opportunities"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature21;
