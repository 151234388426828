import React, { useState } from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/home/banner";
import FeatureCommunities from "../components/home/featureCommunities";
import FeatureSuggestions from "../components/home/featureSuggestions";
import FeatureSearch from "../components/home/featureSearch";
import FeatureTrack from "../components/home/featureTrack";
import CTAmid from "../components/home/ctaMid";
import CTAend from "../components/home/ctaEnd";
import Testimonials from "../components/home/testimonials";
import ScrollingTestimonials from "../components/home/testimonialsScroll";
import UseCases from "../components/home/useCases";
import Pricing from "../components/home/pricing";
import FAQs from "../components/home/faqs";




const Home = ({history, location, match, path}) => {
  const [hideFooter, setHideFooter] = useState(true);

  return (
    <Layout
      fullWidth={true}
      hideHeader={true} // b/c we're including it in the banner ourselves
      hideFooter={hideFooter}
      seoComponent={<SEO title={'Advanced Search for Reddit | GummySearch'} path={path} />}
    >
      <main>
        <Banner />
        
        <FeatureCommunities/>
        <FeatureSuggestions/>
        <FeatureTrack/>
        <CTAmid />

        <div id="reviews" className="hidden lg:block">
          <ScrollingTestimonials/>
        </div>
        <div className="block lg:hidden">
          <Testimonials/>
        </div>

        {/*<UseCases />*/}
        <Pricing />
        <FeatureSearch/>
        <FAQs />
        <CTAend hideFooter={hideFooter} setHideFooter={setHideFooter} />
      </main>
    </Layout>
  );
}

export default Home;
