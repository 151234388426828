const testimonials = [
  
  {
    'name': 'Mike',
    'text': "I can't recommend GummySearch enough. Incredibly powerful for customer research on Reddit / pain points and so much more.",
    'link': 'https://twitter.com/CSMikeCardona/status/1492142648852770817',
  },
  
  {
    'name': 'Michelle',
    'text': 'You would be a fool to pass up on this. Gummy Search has become my market research weapon of choice.',
    'link': 'https://twitter.com/MichellePenny/status/1474136831683960835',
  },
  {
    'name': 'Chris',
    'text': 'GummySearch is one of the greatest products I have discovered in the last two months. This is awesome work.',
    'link': 'https://twitter.com/iamchriswoelk/status/1522405592429740034',
  },
  {
    'name': 'Jen',
    'text': 'GummySearch is the best tool for customer research on reddit',
    'link': 'https://twitter.com/raff_jen/status/1516819731759738882',
  },
  {
    'name': 'Ryan',
    'text': 'Can I write you a testimonial or something? Just had a user sign up for a $79/m trial on MSB after I reached out to them through Gummy',
    'link': 'https://twitter.com/Ryan___Doyle/status/1514617750747975689',
  },
  {
    'name': 'Justyna',
    'text': "I set up the account today, so this is like immediate results! I just started the trial and I think I couldn't have had a better experience",
    'link': 'https://twitter.com/attilczuk/status/1507441696334036993',
  },
  {
    'name': 'Jay',
    'text': "I just came across your service a few days ago externally and fell in love with it. I started telling a few friends about it, that's how hyped I've been about this product.",
    'link': 'https://www.reddit.com/r/Entrepreneur/comments/u3sank/raising_50k_from_customers_via_ltd_sale/i4rg4x7/',
  },
  {
    'name': 'Steph',
    'text': "It's much easier on the eye than using Reddit itself and has helped me to discover some topics that I hadn't even thought about which can fuel my content strategy.",
    'link': 'https://twitter.com/seosteph/status/1511079874684985355',
  },
  // {
  //   'name': 'Chris',
  //   'text': "GummySearch is amazing in digging for nuggets and finding customers for validation on Reddit. It is so powerful.",
  //   'link': 'https://twitter.com/iamchriswoelk/status/1522471574250606592',
  // },
  {
    'name': 'Paul',
    'text': "Reddit has a subreddit for every niche! Want to speed up the search? GummySearch shows you: Popular posts, frequent questions, complaints",
    'link': 'https://twitter.com/pavlos1944/status/1518536792915406848',
  },
  {
    'name': 'Elsie',
    'text': "I've been on reddit for ... sheesh, over 10 years by now, and I recently checked out gummysearch. I love it! The whole platform is helpful and I can't get over how smooth the design and user experience is.",
    'link': 'https://twitter.com/trymicro/status/1522630560019062785',
  },
  {
    'name': 'Mike',
    'text': "I trialed GummySearch and it's pretty remarkable. There's a few different get alerts when someone mentions your service on Reddit type posts, but this is really another level.",
    'link': 'https://www.reddit.com/r/SaaS/comments/smtkca/weekly_feedback_post_saas_products_ideas_companies/hvyo4s8/',
  },
  {
    'name': 'Adam',
    'text': "GummySearch has the coolest onboarding I've seen in a while, maybe ever. A user persona for a first-time Gummysearch user as a tutorial? Genius.",
    'link': 'https://twitter.com/AdamJRochette/status/1501698392556064771',
  },
  {
    'name': 'Rich',
    'text': "Took GummySearch for a spin tonight. I feel like I was given the keys to some secret CIA spy code!! ",
    'link': 'https://twitter.com/flying_hacker/status/1523636064849899520',
  },
  {
    'name': 'Adam',
    'text': "GummySearch has been invaluable to me as a software entrepreneur. I'm looking for real pain points and problems to solve for people, and GummySearch is purpose-built for this.",
    'link': 'https://www.trustpilot.com/reviews/6265479b0c4813f76bf32a9c',
  },
  {
    'name': 'Edward',
    'text': "Gummy Search helps me get more out of Reddit. I use it to discover what people are saying about my brand and services",
    'link': 'https://www.trustpilot.com/reviews/626293667b2618e9fea2db6a',
  },
  // {
  //   'name': 'Duy',
  //   'text': "Don't let the name fool you, Gummysearch is no friendly gummybear. It's a franken-monster of a tool, which allows you to quickly create different audiences based on interests and the subreddits they're in.",
  //   'link': 'https://www.trustpilot.com/reviews/626117787b2618e9fea19aaa',
  // },
  // {
  //   'name': 'Joshua',
  //   'text': "This is a product that aims to do one thing - help you understand your target audience better. It does that one thing VERY well on an organic platform (Reddit) that can be best described as chaotic, random, and even intimidating to the uninitiated",
  //   'link': 'https://www.trustpilot.com/reviews/625d92232b3c3c43cfd5ea8f',
  // },
  {
    'name': 'Laine',
    'text': "I know it's hard to decide what tools to spend money on in the early days of running a business, but I'd pay for GummySearch a thousand times over",
    'link': 'https://www.trustpilot.com/reviews/625d9213c7628b203bafbd8f',
  },
  {
    'name': 'Vivek',
    'text': "It is an excellent tool for finding the voice of your customer. You don't have to waste your time mindlessly scrolling through countless Reddit discussions to find out what your audience wants.",
    'link': 'https://www.trustpilot.com/reviews/625d83892b3c3c43cfd5dca3',
  },
]

export {
    testimonials,
}

