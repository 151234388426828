import React from 'react';
import Image from '../image'

import SignupModalTrigger from './signupModal'

const Feature20 = () => {
  return (
    <section className="bg-slate-light font-dm-sans py-36 mx-6">
      {/*<div className="p-6 sm:pt-12 bg-gray-800 max-w-default md:m-auto rounded-3xl">*/}
        <div className="max-w-default mx-auto mt-2 mb-6">
          <div className="sm:text-center">
            <h2 className="font-extrabold text-4xl sm:text-5xl m-auto mb-4 sm:mb-8">
              The most advanced search for Reddit
            </h2>
            <p className="text-lg sm:text-desktop-subheading text-slate-body leading-10">
              Target any audience, or all of Reddit<br/>
              Filter down to just what you need<br/>
              Visualize the results of any search
            </p>
          </div>
          <div className="mt-8">
            <div className="p-2 sm:p-4 rounded-2xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
              <Image fileName="feature-search.png"
                className="m-auto rounded-2xl"
                src="/assets/features/showcase-area.svg"
                alt="Advanced Search for Reddit"
              />
            </div>
          </div>
        </div>

        <div className="p-8 pt-12 text-center">
          <SignupModalTrigger initiator="feature-search">
            <div className="px-12 py-4 mx-auto inline-block cursor-pointer font-medium text-white rounded-lg text-desktop-paragraph font-dm-sans bg-green-500 hover:opacity-90">
              Take it for a spin
            </div>
          </SignupModalTrigger>
        </div>
      {/*</div>*/}
    </section>
  );
};

export default Feature20;
