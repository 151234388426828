
import React, { useState } from 'react';
import { Link } from "gatsby"

import XIcon from '@heroicons/react/outline/XIcon';

import Image from "../image"
import Header from '../header'
import SignupModalTrigger from './signupModal'


const Hero9 = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <section>
      <div>
        <div className="pt-6 pb-[50px] md:pb-[90px] md:px-0 bg-gradient-to-t from-cyan-400 to-cyan-800 font-dm-sans pt-0 relative">
          <div className="absolute h-full w-full bg-black z-0 opacity-40"></div>

          <div>
            <Header lightHeader={true} />
          </div>

          <section className="relative px-6 m-auto lg:px-0 max-w-default">
            <div className="mt-[50px] md:mt-20 sm:text-center m-auto">
              <div className="max-w-7xl m-auto">
                {/*<h1 className="font-extrabold text-slate-headline text-mobile-h1 md:text-desktop-h1 leading-tight">
                  Audience Research for Reddit
                </h1>
                <p className="mt-[30px] text-desktop-subheading font-semibold">
                  Discover pain points, preferences, and what solutions your customers are eager to pay for.
                </p>*/}
                {/*<h1 className="font-extrabold text-slate-headline text-mobile-h1 md:text-desktop-h1 leading-tight">
                  Understand the voice of your customer
                </h1>
                <p className="mt-[30px] text-desktop-subheading font-semibold max-w-2xl mx-auto">
                  An audience research kit for Reddit to discover pain points, solution needs, and what people are eager to pay for
                </p>*/}
                {/*<h1 className="font-extrabold text-slate-headline text-mobile-h1 md:text-desktop-h1 leading-tight">
                  Audience research tool for Reddit
                </h1>
                <p className="mt-[30px] text-desktop-subheading font-semibold max-w-3xl mx-auto">
                  Explore any niche. Discover pain points, content opportunities, and what solutions people are eager to pay for.
                </p>*/}
                {/*<h1 className="font-extrabold text-slate-headline text-mobile-h1 md:text-desktop-h1 leading-tight">
                  Understand your target customers
                </h1>
                <p className="mt-[30px] text-desktop-subheading font-semibold max-w-2xl mx-auto">
                  Dive into any niche. Find pain points, content opportunities, and what solutions people are eager to pay for.
                </p>*/}

                <h1 className="font-extrabold text-4xl sm:text-5xl leading-snug">
                  <span className="bg-cyan-600 text-white px-2 -mx-1 rounded-lg">Audience Research</span> tool for <span className="bg-cyan-600 text-white px-2 -mx-1 rounded-lg"  style={{'backgroundColor': '#FF5700'}}>Reddit</span>
                </h1>
                {/*<p className="mt-8 text-xl sm:text-2xl font-semibold max-w-5xl mx-auto">
                  GummySearch helps you find, organize, and search their communities
                </p>*/}
                {/*<p className="mt-8 text-xl sm:text-2xl font-semibold max-w-5xl mx-auto">
                  GummySearch helps you find pain points and what people are eager to pay for
                </p>*/}
                {/*<p className="mt-8 text-xl sm:text-2xl font-semibold max-w-5xl mx-auto">
                  Explore pain points, content ideas, and what people are eager to pay for
                </p>*/}
                <p className="mt-8 text-xl sm:text-2xl font-semibold max-w-5xl mx-auto">
                  Search for pain points, solutions to be built, content ideas, and sales leads
                </p>

              </div>
              <div className="flex flex-col justify-center mt-8 md:space-x-4 md:flex-row md:mt-10">
                <SignupModalTrigger initiator="banner-button">
                  <div className="px-8 py-4 font-medium inline-block cursor-pointer shadow-2xl text-white text-center rounded-lg bg-green-500 text-desktop-paragraph hover:opacity-90">
                    Unlock Reddit
                  </div>
                </SignupModalTrigger>
                {/*<button className="px-8 py-4 mt-2 font-medium transition-all duration-300 border-2 rounded-lg md:mt-0 text-slate-blue border-slate-blue hover:text-white hover:bg-slate-blue text-desktop-paragraph">
                  Demo Button
                </button>*/}
              </div>
              <div className="hidden sm:block mt-16">
                <div className="px-0 w-full min-h-[400px] bg-gray-900 rounded-3xl shadow-2xl">
                  <Image fileName="feature-audiences-md.png" alt="GummySearch Audience Discovery"
                    className="mt-0 w-full h-auto rounded-3xl"
                  />
                </div>
              </div>
              <div className="hidden sm:block mt-[-143px] clear-both" />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Hero9;
