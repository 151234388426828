// use cases and individual testimonials for them
import React, { useState } from 'react';
import Image from '../image'
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';

const tabs = [
  {
    name: 'Idea Generation',
    id: 'idea-generation',
  },
  {
    name: 'Solution Validation',
    id: 'solution-validation',
  },
  {
    name: 'Lead Generation',
    id: 'lead-generation',
  },
  {
    name: 'Keyword Research',
    id: 'keyword-research',
  },
  {
    name: 'Content Distribution',
    id: 'content-distribution',
  },
];

const MobileMenu = () => (
  <div>
    <div></div>
  </div>
);

const Header = ({ openMenu, setOpenMenu, selectedID, setSelectedID }) => (
  <section className="max-w-[680px] m-auto">
    <div>
      <h2 className="font-medium leading-tight text-center text-mobile-h2 md:text-desktop-h2 text-slate-headline">
        Your tool at every stage of your journey
      </h2>
      <h5 className="font-normal mt-[30px] text-desktop-subheading text-slate-body text-center">
        GummySearch helps you with every stage of your audience discovery journey. <br/>
        Here are a few of the most common use-cases.
      </h5>
    </div>
    <div className="hidden md:block mt-[54px]">
      <div
        className={`items-center justify-center md:h-auto overflow-hidden space-y-4 md:space-y-0 md:flex md:space-x-6`}
      >
        {tabs.map((tab) => (
          <div
            onClick={() => setSelectedID(tab.id)}
            key={tab.id}
            className={` ${
              selectedID == tab.id
                ? 'bg-slate-blue text-white'
                : 'bg-[#f2f2f2] text-slate-body'
            } px-6 py-4 md:py-2 rounded-full cursor-pointer  group hover:bg-slate-blue hover:text-white`}
          >
            <span className="group-hover:text-white">{tab.name}</span>
          </div>
        ))}
      </div>
    </div>
    <div className="box-border relative mt-8 md:hidden">
      <ChevronDownIcon className="absolute right-0 w-4 mr-10 top-5" />
      <select className="w-full px-6 py-4 bg-transparent border-2 border-gray-200 rounded-full outline-none appearance-none focus:outline-none">
        {tabs.map((tab) => (
          <option key={tab.id} value={tab.id}>
            {tab.name}
          </option>
        ))}
      </select>
    </div>
  </section>
);

/**
 * USE 'selectedID' to set the currently selected tab.
 */

const SelectedDetailTab = ({ selectedID }) => {
  if (selectedID == 'idea-generation') {
    return (
      <div className="flex items-center justify-center p-8 mx-6 md:mx-0 rounded-3xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
        <Image fileName="screenshot-audiences.png" src="/assets/features/showcase-area.svg" alt="" />
      </div>
    );
  }
  if (selectedID == 'solution-validation') {
    return (
      <div className="flex items-center justify-center p-8 mx-6 md:mx-0 rounded-3xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
        <Image fileName="screenshot-audiences.png" src="/assets/features/showcase-area.svg" alt="" />
      </div>
    );
  }
  if (selectedID == 'lead-generation') {
    return (
      <div className="flex items-center justify-center p-8 mx-6 md:mx-0 rounded-3xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
        <Image fileName="screenshot-audiences.png" src="/assets/features/showcase-area.svg" alt="" />
      </div>
    );
  }
  if (selectedID == 'keyword-research') {
    return (
      <div className="flex items-center justify-center p-8 mx-6 md:mx-0 rounded-3xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
        <Image fileName="screenshot-audiences.png" src="/assets/features/showcase-area.svg" alt="" />
      </div>
    );
  }
  if (selectedID == 'content-distribution') {
    return (
      <div className="flex items-center justify-center p-8 mx-6 md:mx-0 rounded-3xl bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end">
        <Image fileName="screenshot-audiences.png" src="/assets/features/showcase-area.svg" alt="" />
      </div>
    );
  }
};

const TestimonialCard = ({ image }) => (
  <div className="w-full transition-all duration-300 bg-gray-800 rounded-2xl md:rounded-3xl hover:shadow-sm">
    <div className="p-5">
      <div className="flex justify-center max-w-[362px]">
        <Image fileName="screenshot-audiences.png" className="w-[50px] h-[50px] mr-[7px]" src={image} />
        <div className="text-left ml-[7px]">
          <p className="text-base leading-snug font-dm-sans text-slate-body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu, elit, a
            volutpat enim, massa id. Vestibulum faucibus.
          </p>
          <p className="text-sm mt-[6px] opacity-50 font-dm-sans text-slate-body">
            Cameron Williamson, CEO, Stripe
          </p>
        </div>
      </div>
    </div>
  </div>
);

const Testimonial3 = () => (
  <section className="py-12 bg-slate-light mb-6">
    <div className="text-center">
      <section className="">
        <div className="mx-6 space-y-6 md:m-auto max-w-default">
          <div className="md:flex space-y-[30px] lg:space-y-0 lg:space-x-[30px]">
            <TestimonialCard image={'/assets/testimonials/author1.png'} />
            <TestimonialCard image={'/assets/testimonials/author2.png'} />
            <TestimonialCard image={'/assets/testimonials/author3.png'} />
          </div>
          <div className="md:flex space-y-7 lg:space-y-0 lg:space-x-7">
            <TestimonialCard image={'/assets/testimonials/author4.png'} />
            <TestimonialCard image={'/assets/testimonials/author5.png'} />
            <TestimonialCard image={'/assets/testimonials/author6.png'} />
          </div>
        </div>
      </section>
    </div>
  </section>
);



const Feature7 = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedID, setSelectedID] = useState(tabs[0].id);

  return (
    <section className="bg-slate-light font-dm-sans py-12 md:py-[90px]">
      <div className="mx-6 md:mx-0" id={'feature-7-container'}>
        <Header
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />
      </div>
      <div className="m-auto mt-12 max-w-default">
        <SelectedDetailTab selectedID={selectedID} />
      </div>

      <Testimonial3 />
    </section>
  );
};

export default Feature7;
