import React from 'react';
import { Link } from "gatsby"

import CheckIcon from '@heroicons/react/outline/CheckIcon';
import SignupModalTrigger from './signupModal'


const PrimaryButton = () => (
  <div className="pt-12">
    <SignupModalTrigger initiator="cta-mid-button">
      <div className="px-12 py-4 font-medium inline-block cursor-pointer text-white rounded-lg text-desktop-paragraph font-dm-sans bg-green-500 hover:opacity-90">
        Find your audience
      </div>
    </SignupModalTrigger>
  </div>
);

const CTA1 = () => (
  <section className="py-16 md:py-[90px] bg-slate-light px-6 lg:px-0">
    <div className="max-w-6xl px-6 py-16 text-center md:py-24 md:m-auto bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end rounded-3xl">
      <div className="max-w-xl m-auto">
        <h5 className="text-base font-bold uppercase font-dm-sans text-white opacity-50">
          Claim your free trial today
        </h5>
        <h2 className="mt-4 font-extrabold text-4xl sm:text-5xl">
          Ready to discover the voice of your customer?
        </h2>
        <div className="mx-auto w-auto inline-block mt-8 text-lg space-y-2">
          <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/> Completely free to try</div>
          <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/> No credit card required</div>
          <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/> Results in 5 minutes</div>
        </div>
      </div>
      <PrimaryButton />
    </div>
  </section>
);

export default CTA1;
