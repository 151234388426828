import React from 'react';
import Image from '../image'

import UserGroup from '@heroicons/react/outline/UserGroupIcon';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import FastForward from '@heroicons/react/outline/FastForwardIcon';
import ViewGridAdd from '@heroicons/react/outline/ViewGridAddIcon';
import PresentationChartLine from '@heroicons/react/outline/PresentationChartLineIcon';
import ArrowRight from '@heroicons/react/solid/ArrowRightIcon';

import SignupModalTrigger from './signupModal'


const FeatureItem = ({ className, title, description, icon }) => {
  const FeatureIcon = icon;
  return (
    <div className={`${className}`}>
      <div className="flex">
        <div className="flex flex-shrink-0 items-center justify-center w-12 h-12 rounded-full bg-slate-orange">
          <FeatureIcon className="h-6 w-6 text-gray-100" />
        </div>
        <div className="ml-4">
          <h3 className="font-bold text-desktop-h4">{title}</h3>
          <p className="text-lg text-white opacity-70">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

const Feature4 = () => (
  <section className="bg-slate-light font-dm-sans mt-20">
    <div className="max-w-default py-12 md:py-[90px] mx-6 lg:m-auto">
      <div className="w-full">
        <div className="sm:text-center">
          <h2 className="font-extrabold text-4xl sm:text-5xl mb-2">
            Reddit is a goldmine for audience research
          </h2>
          {/*<p className="text-desktop-subheading text-slate-body">
            (if you know where to look)
          </p>*/}

          <p className="block text-desktop-subheading text-slate-body">
            Ideate businesses, validate solutions, create content, and discover new customers.
          </p>
          {/*<div className="hidden md:grid grid-cols-1 md:grid-cols-4 max-w-5xl mx-auto my-12 text-xl font-semibold md:space-x-8 text-center">
            <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/>Ideate businesses</div>
            <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/>Validate solutions</div>
            <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/>Create content</div>
            <div className="flex items-center"><CheckIcon className="h-4 w-4 mr-2"/>Discover customers</div>
          </div>*/}
        </div>
      </div>
      <div className="sm:flex items-center justify-center md:flex mt-20">
        <div className="sm:w-1/3 flex-wrap items-center space-y-8 sm:space-y-16 sm:pr-8">
          <FeatureItem title="130,000 active communities" icon={UserGroup} description="One for every niche, multiple for most" />
          <FeatureItem title="Find them in minutes" icon={FastForward} description="Community discovery is quick & easy" />
          <FeatureItem title="Discover breakout niches" icon={PresentationChartLine} description="Top active/growing communities" />
          <FeatureItem title="Organize multiple audiences" icon={ViewGridAdd} description="Multiple customer types? No problem" />
          <SignupModalTrigger initiator="feature-communities">
            <div className="mt-8 flex items-center">
              <p className="text-base text-slate-body hover:underline cursor-pointer">
                See if your customers are on Reddit
              </p>
              <ArrowRight className="w-4 ml-2" />
            </div>
          </SignupModalTrigger>
        </div>
        <div className="sm:w-2/3 items-center justify-center m-auto mt-24 md:mt-0 bg-gradient-to-r">
          {/*<div className="bg-gradient-to-r from-feeling-moody-start flex justify-center items-center to-feeling-moody-end m-auto h-[326px] w-[326px] md:h-[650px] md:w-[650px] rounded-full p-[22px]">
            <Image fileName="feature-audiences-md.png"
              alt="Discovery of multiple audiences"
              className="rounded-lg"
            />
          </div>*/}
          <div className="w-full p-4 justify-center flex items-center bg-gradient-to-r from-feeling-moody-start to-feeling-moody-end rounded-3xl">
            <Image fileName="feature-top-growing-md.png" className="w-6" alt="Discovery of multiple audiences"
              className="w-full rounded-2xl shadow-xl"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Feature4;
