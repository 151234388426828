import React from 'react';

const faqs = [
  {
    'question': "This looks cool, but can't I do the same on Reddit?",
    'answer': "Reddit is for discovering new content. GummySearch is built specifically for those that seek insights from the millions of daily conversations happening on Reddit."
  },
  {
    'question': 'Do I need a Reddit account to use GummySearch?',
    'answer': "No. You can browse public posts & comments without a Reddit account. However, an account is recommended to contribute to any conversations you find via GummySearch."
  },
  {
    'question': "I'm not sure if my target audience is on Reddit. How do I find out?",
    'answer': "You'll be able to figure that out in a matter of minutes during onboarding. If you still aren't able to find any, reach out and you will get help."
  },
  {
    'question': "I'm busy, does this tool take a long time to pick up?",
    'answer': "GummySearch was built for busy startup founders like you, to save you time and get you results quickly. Check it out and you'll see for yourself."
  },
  {
    'question': "I hear Redditors are strict. Will I get downvoted or banned?",
    'answer': "They are nicer than the rumors make them out to be. Add more to the community than you take, and you'll be warmly welcomed. We'll guide you through it!"
  },
  // {
  //   'question': "Why the Gummy bear?",
  //   'answer': "GummySearch "
  // },
]


const Feature19 = () => {
  return (
    <section className="bg-slate-light font-dm-sans py-12 md:py-[90px]">
      <div className="m-auto max-w-3xl">
        <h2 className="mt-4 font-extrabold text-5xl mx-auto text-center">
          FAQs
        </h2>
        <div className="mt-[70px] mx-6 md:mx-0 space-y-8">
          {faqs.map(f => (
            <div key={f.question}>
              <h5 className="text-xl sm:text-2xl font-bold mb-2">
               {f.question}
              </h5>
              <p className="sm:text-desktop-paragraph text-slate-body">
               {f.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Feature19;
