import React from 'react';

const testimonials = [
  
  {
    'name': 'Mike',
    'text': "I can't recommend GummySearch enough. Incredibly powerful for customer research on Reddit / pain points and so much more.",
    'link': 'https://twitter.com/CSMikeCardona/status/1492142648852770817',
    'image': ''
  },
  
  {
    'name': 'Michelle',
    'text': 'You would be a fool to pass up on this. Gummy Search has become my market research weapon of choice.',
    'link': 'https://twitter.com/MichellePenny/status/1474136831683960835',
    'image': ''
  },
  {
    'name': 'Chris',
    'text': 'GummySearch is one of the greatest products I have discovered in the last two months. This is awesome work.',
    'link': 'https://twitter.com/iamchriswoelk/status/1522405592429740034',
    'image': ''
  },
  // {
  //   'name': 'Jen',
  //   'text': 'GummySearch is the best tool for customer research on reddit',
  //   'link': 'https://twitter.com/raff_jen/status/1516819731759738882',
  //   'image': ''
  // },
  
  {
    'name': 'Ryan',
    'text': 'Can I write you a testimonial or something? Just had a user sign up for a $79/m trial on MSB after I reached out to them through Gummy',
    'link': 'https://twitter.com/Ryan___Doyle/status/1514617750747975689',
    'image': ''
  },
  {
    'name': 'Justyna',
    'text': "I set up the account today, so this is like immediate results! I just started the trial and I think I couldn't have had a better experience",
    'link': 'https://twitter.com/attilczuk/status/1507441696334036993',
    'image': ''
  },
  {
    'name': 'Jay',
    'text': "I just came across your service a few days ago externally and fell in love with it. I started telling a few friends about it, that's how hyped I've been about this product.",
    'link': 'https://www.reddit.com/r/Entrepreneur/comments/u3sank/raising_50k_from_customers_via_ltd_sale/i4rg4x7/',
    'image': ''
  },
]

const Testimonial4 = () => (
  <section className="py-12 md:py-[90px] bg-slate-light">
    <div className="text-center">

      <section className="sm:mt-8 md:mt-[55px] max-w-7xl mx-auto sm:pl-6">
        <div className="grid sm:grid-cols-3 p-6 md:p-0">
          
          {testimonials.map((t) => (
            <div key={t.link} className="relative p-4 sm:p-8 pb-4 sm:mr-6 mb-2 sm:mb-6 bg-gray-800 rounded-xl text-sm sm:text-lg flex flex-col">
              <p className="absolute top-2 left-2 text-left text-slate-body opacity-20 text-[72px] leading-none font-dm-sans italic">
                "
              </p>
              {/*<div className="relative flex items-center justify-center">
                <img
                  className="w-16 h-16"
                  src="/assets/testimonials/author1.png"
                />
              </div>*/}
              <p className="text-slate-body mb-1">
                {t.text}
              </p>
              <p className="mt-auto text-sm text-slate-body text-right">
                - <a href={t.link} target="_blank" className="border-b border-dashed border-gray-500 hover:border-gray-200 cursor-pointer">{t.name}</a>
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  </section>
);

export default Testimonial4;
